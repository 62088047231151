import React from "react";
import mediaURL from "../utils/mediaUrl";
import {useTranslation} from "react-i18next";
import {getLocalizedName} from "../utils/nameFormatter";

const isImageFile = (file) => /\.(png|jpeg|jpg|gif)$/i.test(file);
const isVideoFile = (file) => /\.(webm|mp4)$/i.test(file);


export default function TemplateInfoModal({ application, onClose, onReject, onApprove }) {
    const { t } = useTranslation();
    const { channel_sender, advertisement_template } = application;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 mb-16">
            <div className="bg-white rounded-lg max-w-lg w-full p-6 overflow-hidden relative h-full overflow-y-auto">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">{t('advertisement_template.application_details')}</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">X</button>
                </div>

                <div className="space-y-4">
                    {/* Channel Sender Info */}
                    <div>
                        <h3 className="text-lg font-semibold">
                            {t('advertisement_template.channel')}:
                            <a
                                href={channel_sender.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="font-semibold text-lg hover:underline"
                                onClick={(e) => e.stopPropagation()}
                            >
                                {channel_sender.name}
                            </a>
                        </h3>
                        <div className="text-sm text-gray-500">{t('advertisement_template.category')}: {getLocalizedName(channel_sender.category)}</div>
                        <div className="text-sm text-gray-500">{t('advertisement_template.country')}: {channel_sender.country.country_code}</div>
                        <div className="text-sm text-gray-500">{t('advertisement_template.followers')}: {channel_sender.followers}</div>
                    </div>

                    {/* Advertisement Template Info */}
                    <div>
                        <h3 className="text-lg font-semibold">{t('advertisement_template.template')}: {advertisement_template.title}</h3>
                        <p className="text-sm">{advertisement_template.text}</p>
                        <div className="grid gap-2 grid-cols-2 justify-center items-center mt-2">
                            {advertisement_template.media_files.map((file, idx) => (
                                <div key={idx} className="w-full rounded-md shadow-sm">
                                    {isImageFile(file) ? (
                                        <img
                                            src={mediaURL(file)}
                                            alt={`media ${idx + 1}`}
                                            className="w-full object-cover rounded-md"
                                        />
                                    ) : isVideoFile(file) ? (
                                        <video
                                            src={mediaURL(file)}
                                            className="w-full h-full object-cover rounded-md"
                                            controls
                                            preload="metadata"
                                        />
                                    ) : null}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="flex justify-end space-x-2 mt-4">
                    <button onClick={onReject} className="px-4 py-2 bg-red-500 rounded-md">{t('advertisement_template.reject')}</button>
                    <button onClick={onApprove} className="px-4 py-2 bg-blue-500 text-white rounded-md">{t('advertisement_template.approve')}</button>
                </div>
            </div>
        </div>
    );
}
