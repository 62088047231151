import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLanguage, selectLanguageCode } from '../features/auth/authSlice';
import { useTranslation } from 'react-i18next';

export default function SettingsPage() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const languageCode = useSelector(selectLanguageCode);
    const { status } = useSelector((state) => state.auth);

    const handleLanguageChange = (lang) => {
        dispatch(updateLanguage(lang));
    };

    return (
        <div className="flex flex-col items-center justify-center h-full space-y-4">
            <h1 className="text-3xl font-bold">{t('settingsPage.title')}</h1>
            <div className="flex space-x-4">
                <button
                    onClick={() => handleLanguageChange('en')}
                    className={`px-4 py-2 rounded ${languageCode === 'en' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
                >
                    English
                </button>
                <button
                    onClick={() => handleLanguageChange('ru')}
                    className={`px-4 py-2 rounded ${languageCode === 'ru' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
                >
                    Русский
                </button>
            </div>
            {status === 'loading' && <p>{t('settingsPage.updatingLanguage')}</p>}
        </div>
    );
}
