import React, { useEffect, useState } from "react";
import {
    HomeIcon as HomeIconOutline,
    CubeIcon as CubeIconOutline,
    PaperAirplaneIcon as PaperAirplaneIconOutline,
    UsersIcon as UsersIconOutline,
    Cog6ToothIcon as Cog6ToothIconOutline,
} from "@heroicons/react/24/outline";
import {
    HomeIcon as HomeIconSolid,
    CubeIcon as CubeIconSolid,
    PaperAirplaneIcon as PaperAirplaneIconSolid,
    UsersIcon as UsersIconSolid,
    Cog6ToothIcon as Cog6ToothIconSolid,
} from "@heroicons/react/24/solid";
import {APPS_ROUTE, CHANNELS_ROUTE, REF_ROUTE, SENT_APPS_ROUTE, SETTINGS_ROUTE} from "../constants/routerConstants";
import { useNavigate, useLocation } from "react-router-dom";
import {useTranslation} from "react-i18next";

const Tab = ({ isActive, outlineIcon: OutlineIcon, solidIcon: SolidIcon, label, onClick }) => {
    return (
        <div
            className={`flex flex-col items-center cursor-pointer transition-transform ${
                isActive ? "text-darkBlue scale-110" : "text-primaryGray hover:scale-105"
            }`}
            onClick={onClick}
            style={{ minWidth: "70px" }}
        >
            {isActive ? (
                <SolidIcon className="w-6 h-6 transition-colors duration-300" />
            ) : (
                <OutlineIcon className="w-6 h-6 transition-colors duration-300" />
            )}
            <span
                className={`text-sm transition-colors duration-300 text-center ${
                    isActive
                        ? "break-words leading-tight" // Show full text
                        : "overflow-hidden text-ellipsis whitespace-nowrap" // Hide overflow
                }`}
                style={{ maxWidth: isActive ? "none" : "70px" }} // Allow full width for active tab
            >
                {label}
            </span>
        </div>
    );
};


export default function Footer() {
    const [activeTab, setActiveTab] = useState("channels");
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const tabs = [
        {
            outlineIcon: HomeIconOutline,
            solidIcon: HomeIconSolid,
            label: t("footer.channels"),
            name: "channels",
            url: CHANNELS_ROUTE
        },
        {
            outlineIcon: CubeIconOutline,
            solidIcon: CubeIconSolid,
            label: t("footer.apps"),
            name: "apps",
            url: APPS_ROUTE
        },
        {
            outlineIcon: PaperAirplaneIconOutline,
            solidIcon: PaperAirplaneIconSolid,
            label: t("footer.sent_apps"),
            name: "sent",
            url: SENT_APPS_ROUTE
        },
        {
            outlineIcon: UsersIconOutline,
            solidIcon: UsersIconSolid,
            label: t("footer.ref"),
            name: "ref",
            url: REF_ROUTE
        },
        {
            outlineIcon: Cog6ToothIconOutline,
            solidIcon: Cog6ToothIconSolid,
            label: t("footer.settings"),
            name: "settings",
            url: SETTINGS_ROUTE
        },
    ]

    useEffect(() => {
        const path = location.pathname;
        if (path === CHANNELS_ROUTE) {
            setActiveTab("channels");
        } else if (path === APPS_ROUTE) {
            setActiveTab("apps");
        } else if (path === SENT_APPS_ROUTE) {
            setActiveTab("sent");
        } else if (path === REF_ROUTE) {
            setActiveTab("ref");
        } else if (path === SETTINGS_ROUTE) {
            setActiveTab("settings");
        }
    }, [location]);

    return (
        <div className="bg-white p-2 border-t-2 sticky top-[100vh] h-16">
            <div className="flex justify-around items-center">
                {tabs.map(tab => (
                    <Tab
                        key={tab.name}
                        isActive={activeTab === tab.name}
                        outlineIcon={tab.outlineIcon}
                        solidIcon={tab.solidIcon}
                        label={tab.label}
                        onClick={() => navigate(tab.url)}
                    />
                ))}
            </div>
        </div>
    );
}
