import React, { useState } from "react";
import { EllipsisHorizontalIcon, PlusCircleIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { MapPinIcon, UsersIcon } from '@heroicons/react/24/solid';
import numberFormatter from "../utils/numberFormatter";
import mediaURL from "../utils/mediaUrl";
import { useDispatch } from 'react-redux';
import {refreshChannel} from "../features/channels/channelsSlice";
import TemplatesModal from "./TemplatesModal";
import {getLocalizedName} from "../utils/nameFormatter";
import {useTranslation} from "react-i18next";

function hashStringToNumber(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
}

function getGradientClassFromTitle(id) {
    const gradients = [
        'bg-gradient-purple-blue',
        'bg-gradient-green-blue',
        'bg-gradient-pink-purple',
        'bg-gradient-orange-yellow',
        'bg-gradient-indigo-purple',
        'bg-gradient-red-orange',
        'bg-gradient-teal-blue',
        'bg-gradient-yellow-green',
        'bg-gradient-gray-blue',
        'bg-gradient-pink-yellow',
    ];

    const hashValue = Math.abs(hashStringToNumber(id.toString()));
    const index = hashValue % gradients.length;
    return gradients[index];
}

export default function Channel({ channelDetails }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleRefresh = (uuid) => {
        dispatch(refreshChannel(uuid));
        setIsMenuOpen(false);
    };

    const toggleMenu = (e) => {
        e.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
    };

    const openTemplates = () => {
        setIsMenuOpen(false);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => setIsModalOpen(false);

    return (
        <div key={channelDetails?.id} className={`flex flex-col w-full ${getGradientClassFromTitle(channelDetails?.uuid)} rounded-xl p-4 shadow-sm relative`}>
            {/* Channel Header */}
            <div className="flex items-start justify-between mb-2" onClick={() => openTemplates(channelDetails?.uuid)}>
                <div className="flex items-center">
                    {channelDetails?.image ?
                        <img src={mediaURL(channelDetails?.image)} alt={channelDetails?.name} className="w-10 h-10 rounded-full" />
                        :
                        <img src="/logo.png" alt={channelDetails?.name} className="w-10 h-10 rounded-full" />
                    }

                    <div className="ml-3">
                        <h3 className="font-semibold text-lg text-gray-800">
                            <a
                                href={channelDetails?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="hover:underline"
                                onClick={(e) => e.stopPropagation()}
                            >
                                {channelDetails?.name}
                            </a>
                        </h3>
                        <p className="text-gray-500 text-sm overflow-clip">{channelDetails?.description?.slice(0, 40)}{channelDetails?.description?.length > 40 ? '...' : null}</p>
                    </div>
                </div>

                {/* Three Dots Icon */}
                <div className="relative">
                    <EllipsisHorizontalIcon className="w-6 h-6 text-gray-500 cursor-pointer" onClick={toggleMenu} />

                    {/* Popup Menu */}
                    {isMenuOpen && (
                        <div className="absolute right-0 mt-2 w-56 bg-white shadow-lg rounded-md z-10">
                            <ul>
                                <li onClick={() => handleRefresh(channelDetails?.uuid)} className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                    {t('channels_page.refresh')}
                                </li>
                                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => openTemplates(channelDetails?.uuid)}>
                                    {t('channels_page.advertisement_templates')}
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>

            {/* Tags */}
            <div className="flex items-center">
                <span className="border-2 border-blue-600 text-blue-600 text-sm font-medium px-2 rounded-full mr-2">
                    {getLocalizedName(channelDetails?.category)}
                </span>
            </div>
            <div className="flex flex-row-reverse h-6">
                {channelDetails?.applications?.length > 0 ?
                    <div className="flex bg-gray-100 text-gray-600 text-sm font-medium px-3 py-1 rounded-full items-center hidden">
                        Request ({channelDetails?.applications?.length})
                    </div>
                    :
                    null
                }
            </div>

            {/* Channel Footer */}
            <div className="flex justify-between items-center mt-4">
                <div className="flex items-center space-x-2 text-gray-500">
                    <MapPinIcon className="w-5 h-5" />
                    <span>{channelDetails?.country?.country_code}</span>
                    <UsersIcon className="w-5 h-5" />
                    <span>{numberFormatter(channelDetails?.followers)}</span>
                </div>
                {channelDetails.isAdsAdded ?
                    <div className="flex items-center space-x-1 text-green-500 rounded-2xl bg-green-100 py-2 px-4 hidden">
                        <CheckCircleIcon className="w-5 h-5" />
                        <span>ADs added</span>
                    </div>
                    :
                    <div className="flex items-center space-x-1 text-primaryText rounded-2xl bg-primaryWhite py-2 px-4 hidden">
                        <PlusCircleIcon className="w-5 h-5" />
                        <span>Add ADs</span>
                    </div>
                }
            </div>
            <TemplatesModal isOpen={isModalOpen} channel={channelDetails} onClose={handleCloseModal} />
        </div>
    )
}
