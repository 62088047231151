import React, { useState } from 'react';
import Balance from "components/Balance";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import AddChannelModal from "components/AddChannelModal";
import Channel from "../components/Channel";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";


export default function ChannelsPage() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { channels, status } = useSelector((state) => state.channels);
    const { t } = useTranslation();

    const handleCloseModal = () => setIsModalOpen(false);

    return (
        <div className="flex-grow flex flex-col pb-2 gap-2 h-full">
            <div className="flex flex-row justify-between items-center w-full">
                <div onClick={() => setIsModalOpen(true)} className="flex flex-row justify-between items-center w-fit border-2 h-10 py-2 px-3 border-gradient-primary text-gradient-primary rounded-xl border-lightBlue text-lightBlue font-bold gap-2">
                    <PlusCircleIcon className="w-8 h-8" />
                    <p>{t('channels_page.add_channel')}</p>
                </div>
                <Balance />
            </div>

            <p className="text-xl font-bold w-full">{t('channels_page.channels')}</p>

            {status === 'loading' && (
                <div className="w-full flex-grow border-2 border-primaryGray rounded-2xl flex items-center justify-center">
                    <p>{t('channels_page.loading_channels')}</p>
                </div>
            )}

            {channels.length > 0 ? (
                channels.map((channelData, index) => (
                    <Channel key={index} channelDetails={channelData} />
                ))
            ) : (
                status === 'succeeded' && (
                    <div onClick={() => setIsModalOpen(true)}
                         className="w-full flex-grow border-2 border-primaryGray rounded-2xl flex items-center justify-center cursor-pointer">
                        <div className="flex flex-col text-primaryGray items-center">
                            <PlusCircleIcon className="w-24 h-24" />
                            <p className="text-4xl">{t('channels_page.add_channel')}</p>
                        </div>
                    </div>
                )
            )}

            <AddChannelModal isOpen={isModalOpen} onClose={handleCloseModal} />
        </div>
    );
}
