import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import Balance from 'components/Balance';
import numberFormatter from '../utils/numberFormatter';
import { approveApplication, rejectApplication } from '../features/channels/channelsSlice';
import TemplateInfoModal from "../components/TemplateInfoModal";
import {getLocalizedName} from "../utils/nameFormatter";
import {useTranslation} from "react-i18next";

export default function AppsPage() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const channelsData = useSelector((state) => state.channels.channels); // Access channels data from Redux state
    const uniqueChannels = channelsData.map((channel) => channel.name); // Extract unique channel names
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedChannel, setSelectedChannel] = useState(uniqueChannels[0]); // Initialize with the first channel
    const [selectedApplication, setSelectedApplication] = useState(null); // State for selected application in modal
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
    const channelRefs = useRef([]);

    // Filter applications based on selected channel and search term
    const filteredApplications = channelsData
            .find((channel) => channel.name === selectedChannel)
            ?.applications
            .filter((app) => app.channel_sender.name.toLowerCase().includes(searchTerm.toLowerCase()))
            .filter((app) => app.status.toLowerCase() === 'pending' || app.status.toLowerCase() === 'approved')
        || [];

    const handleChannelClick = (channel, index) => {
        setSelectedChannel(channel);
        if (index > 0 && index < uniqueChannels.length - 1) {
            channelRefs.current[index].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }
    };

    // Open the modal for a pending application
    const handleApplicationClick = (application) => {
        if (application.status.toLowerCase() === 'pending') {
            setSelectedApplication(application);
            setIsModalOpen(true);
        }
    };

    // Handle approval of the application
    const handleApprove = () => {
        dispatch(approveApplication(selectedApplication.uuid)); // Dispatch approval action
        setIsModalOpen(false); // Close modal after approval
    };

    const handleReject = () => {
        dispatch(rejectApplication(selectedApplication.uuid))
        setIsModalOpen(false); // Close modal after approval
    };

    return (
        <div className="flex-grow flex flex-col gap-2">
            {/* Header */}
            <div className="flex flex-row justify-between items-center w-full gap-4">
                <div className="flex items-center border-2 rounded-2xl py-2 px-4 max-w-56">
                    <input
                        type="text"
                        className="border-none outline-none bg-transparent w-full"
                        placeholder={t('apps_page.search_applications')}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <MagnifyingGlassIcon className="w-6 h-6 text-gray-500 mr-2" />
                </div>
                <Balance />
            </div>

            {/* Channel Tabs */}
            <div className="flex overflow-x-auto space-x-4 px-4 py-2 my-2 hide-scrollbar">
                {uniqueChannels.map((channel, index) => (
                    <button
                        key={channel}
                        ref={(el) => (channelRefs.current[index] = el)}
                        className={`whitespace-nowrap focus:outline-none ${
                            selectedChannel === channel ? 'font-bold border-b-2 border-blue-500 text-blue-500' : 'text-primaryText'
                        }`}
                        onClick={() => handleChannelClick(channel, index)}
                    >
                        {channel}
                    </button>
                ))}
            </div>

            {/* Application List */}
            <div className="flex flex-col gap-4">
                {filteredApplications.map((application, index) => (
                    <div
                        key={index}
                        className={`flex justify-between items-center rounded-lg p-4 ${
                            application.status.toLowerCase() === 'approved' ? 'bg-gray-100' : null
                        }`}
                        onClick={() => handleApplicationClick(application)}
                    >
                        <div className="flex flex-col space-y-2">
                            <span className="font-semibold text-lg">{application.channel_sender.name}</span>
                            <div className="flex space-x-2 text-sm text-gray-500">
                                <div className="flex px-1 rounded-lg border-2 items-center">
                                    {getLocalizedName(application.channel_sender.category)}
                                </div>
                                <span className="flex px-1 rounded-lg border-2 items-center">
                                    {application.channel_sender.country.country_code}
                                </span>
                                <span className="flex px-1 rounded-lg border-2 items-center">
                                    {numberFormatter(application.channel_sender.followers)}
                                </span>
                            </div>
                        </div>
                        <button
                            className={`px-4 py-2 rounded-lg ${
                                application.status === 'Approved' ? 'bg-green-100 text-green-500' : 'bg-blue-100 text-blue-500'
                            }`}
                        >
                            {t(`application_status.${application.status}`)}
                        </button>
                    </div>
                ))}
            </div>

            {/* Modal for Pending Application */}
            {isModalOpen && selectedApplication && (
                <TemplateInfoModal
                    application={selectedApplication}
                    onClose={() => setIsModalOpen(false)}
                    onApprove={handleApprove}
                    onReject={handleReject}
                />
            )}
        </div>
    );
}
