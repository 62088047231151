import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {XCircleIcon, ArrowLeftIcon, PlusCircleIcon} from "@heroicons/react/24/outline";
import {
    fetchCategories,
    fetchCountries,
    fetchRegisteredChannels,
    createChannel
} from "../features/channels/channelsSlice";
import {useTranslation} from "react-i18next";
import {getLocalizedName} from "../utils/nameFormatter";

export default function AddChannelModal({ isOpen, onClose }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // Fetch categories, countries, and registered channels when the modal opens
    useEffect(() => {
        if (isOpen) {
            dispatch(fetchCategories());
            dispatch(fetchCountries());
            dispatch(fetchRegisteredChannels());
        }
    }, [isOpen, dispatch]);

    // Get categories, countries, registered channels, and loading status from Redux store
    const { categories, countries, registeredChannels, status } = useSelector((state) => state.channels);

    // State for search input and filtered countries
    const [searchInput, setSearchInput] = useState("");
    const [filteredCountries, setFilteredCountries] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [selectedChannel, setSelectedChannel] = useState("");

    // Update filtered countries when search input changes or when countries are fetched
    useEffect(() => {
        if (countries) {
            setFilteredCountries(
                countries.filter(country =>
                    getLocalizedName(country).toLowerCase().includes(searchInput.toLowerCase()) ||
                    country.country_code.toLowerCase().includes(searchInput.toLowerCase())
                )
            );
        }
    }, [searchInput, countries]);

    // Handle category selection
    const handleCategorySelect = (category) => {
        if (category === selectedCategory) {
            setSelectedCategory(null);
        } else {
            setSelectedCategory(category);
        }
    };

    // Handle region selection
    const handleRegionSelect = (region) => {
        if (region === selectedRegion) {
            setSelectedRegion(null);
        } else {
            setSelectedRegion(region);
        }
    };

    // Handle form submission
    const handleSubmit = async () => {
        const data = {
            channel_id: selectedChannel,
            category_id: selectedCategory.id,
            country_id: selectedRegion.id
        }
        dispatch(createChannel(data)).then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {
                setSelectedCategory(null);
                setSelectedRegion(null);
                setSelectedChannel("");
                onClose();
            }
        });
    };

    // Check if all fields are selected to enable the button
    const isFormValid = selectedChannel && selectedCategory && selectedRegion;

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 mb-16">
            <div className="bg-white rounded-lg max-w-lg p-6 overflow-hidden relative h-full overflow-y-auto">
                {/* Close and Back Icons */}
                <div className="absolute top-4 left-4">
                    <ArrowLeftIcon className="w-6 h-6 text-gray-500 cursor-pointer" onClick={onClose} />
                </div>

                {/* Title */}
                <h2 className="text-center text-xl font-bold mb-4">{t('channels_page.adding_channel')}</h2>

                {/* Registered Channels Dropdown */}
                <div className="mb-4 flex w-full gap-2">
                    <select
                        className="w-full border border-gray-300 rounded-lg p-2"
                        value={selectedChannel}
                        onChange={(e) => setSelectedChannel(e.target.value)}
                    >
                        <option value="">{t('channels_page.choose_channel')}</option>
                        {registeredChannels.length > 0 ? (
                            registeredChannels.map((channel) => (
                                <option key={channel.id} value={channel.id}>
                                    {channel.title}
                                </option>
                            ))
                        ) : (
                            status === 'succeeded' ? <option disabled>{t('channels_page.no_channels')}</option> : <option disabled>{t('channels_page.loading_channels')}</option>
                        )}
                    </select>
                    <div className="w-12 h-12 flex justify-center items-center cursor-pointer">
                        <a rel="noopener noreferrer" target="_blank" href={`https://t.me/xdadayatestbot?startchannel&admin=post_messages+invite_users`}>
                            <PlusCircleIcon className="w-10 h-10"/>
                        </a>
                    </div>
                </div>

                {/* Category Chips */}
                <div className="flex flex-wrap gap-2 mb-4">
                    {categories.length > 0 ? (
                        categories.map((category) => (
                            <button
                                key={category.id}
                                onClick={() => handleCategorySelect(category)}
                                className={`py-2 rounded-full text-sm flex items-center justify-center ${
                                    category === selectedCategory
                                        ? "bg-blue-100 text-blue-500 border border-blue-400 px-2"
                                        : "bg-gray-100 text-gray-600 border border-gray-500 px-4"
                                }`}
                            >
                                {getLocalizedName(category)}
                                {category === selectedCategory ? <XCircleIcon className="h-4 w-4" /> : null}
                            </button>
                        ))
                    ) : (
                        <p>{t('channels_page.loading_categories')}</p>
                    )}
                </div>

                {/* Search Region Input */}
                <div className="mb-4">
                    <input
                        type="text"
                        className="w-full border border-gray-300 rounded-lg p-2"
                        placeholder={t('channels_page.search_region')}
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                    />
                </div>

                {/* Regions List */}
                <ul className="space-y-2 overflow-y-auto h-full max-h-80 border-2 rounded-lg p-2">
                    {filteredCountries.length > 0 ? (
                        filteredCountries.map((country) => (
                            <li
                                key={country.id}
                                onClick={() => handleRegionSelect(country)}
                                className={`p-2 rounded-lg cursor-pointer flex justify-between items-center ${
                                    selectedRegion?.id === country.id ? "bg-blue-100 text-blue-600" : "hover:bg-gray-100"
                                }`}
                            >
                                <span>{getLocalizedName(country)}</span>
                                <span>{country.country_code}</span>
                            </li>
                        ))
                    ) : (
                        <li className="p-2 text-gray-500">{t('channels_page.no_region_found')}</li>
                    )}
                </ul>

                <div className="mt-6 flex justify-between items-center">
                    <button
                        className={`w-full bg-gradient-primary text-white font-medium text-lg py-2 px-4 rounded-xl hover:bg-blue-700 active:scale-95 transition-transform duration-150 ease-in-out ${
                            isFormValid ? "cursor-pointer" : "cursor-not-allowed opacity-50"
                        }`}
                        onClick={isFormValid ? handleSubmit : null}
                    >
                        {t('channels_page.add_channel')}
                    </button>
                </div>
            </div>
        </div>
    );
}
