import { AppRoutes } from "routes/AppRoutes";
import { ScrollToTop } from "components/ScrollToTop";
import {toast, ToastContainer} from "react-toastify";
import OnboardingModal from "components/OnboardingModal";
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { authorizeUser, collectLogData, selectLanguageCode } from './features/auth/authSlice';
import { fetchChannels } from './features/channels/channelsSlice';
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import moment from "moment";
import "moment/locale/ru"; // Import the locales you need

// lt --port 3000 --subdomain xdadaya
// ssh -R 80:localhost:3000 localhost.run

export default function App() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { user, isAuthenticated, status } = useSelector((state) => state.auth);
    const languageCode = useSelector(selectLanguageCode);


    useEffect(() => {
        i18n.changeLanguage(languageCode);
        moment.locale(languageCode);
    }, [languageCode]);

    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.ready();
            window.Telegram.WebApp.expand();
        } else {
            toast.error('Telegram WebApp API is unavailable.');
        }
    }, []);

    useEffect(() => {
        let initData = window.Telegram?.WebApp?.initData;
        const ref = window.Telegram?.WebApp?.initDataUnsafe?.start_param;
        dispatch(authorizeUser({'initData': initData, 'ref': ref})).then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {
                dispatch(fetchChannels());
                dispatch(collectLogData());
            }
        });

    }, [dispatch]);

    const isOnboardingFinished = user?.finished_onboarding;

    return (
        <ScrollToTop>
            <div className="min-h-screen">
                {isAuthenticated ?
                    <>
                        <OnboardingModal isOpen={!isOnboardingFinished} />
                        <AppRoutes />
                    </>
                    :
                    null
                }
                {status === 'loading' && <p>{t('loading')}</p>}
                {status === 'failed' && <p>Error: Authorization failed</p>}
                <ToastContainer
                    position="top-right"
                    autoClose={2500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </ScrollToTop>
    );
}
