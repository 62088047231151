import React from "react";
import Moment from "react-moment";
import {useTranslation} from "react-i18next";
export default function BalanceChangesModal({ isOpen, onClose, balanceChanges, balance }) {
    const { t } = useTranslation();
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 mb-16">
            <div className="bg-white rounded-lg p-6 w-full max-w-md h-full overflow-y-auto">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">{t(`balanceChangeReasons.balance_changes`)}</h2>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 cursor-pointer" onClick={onClose}>
                        <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                    </svg>
                </div>

                {/* Display Actual Balance */}
                <div className="mb-4 text-center">
                    <p className="text-lg font-semibold">{t(`balanceChangeReasons.current_balance`)}</p>
                    <p className="text-2xl font-bold">{balance}</p>
                </div>

                {/* List of balance changes */}
                <ul className="space-y-2">
                    {balanceChanges.length > 0 ? (
                        balanceChanges.map((change, index) => (
                            <li key={index} className="flex justify-between">
                                <span>{t(`balanceChangeReasons.${change.reason}`)} <Moment fromNow>{change.changed_at}</Moment></span>
                                <span className={`font-semibold ${change.balance > 0 ? "text-green-500" : "text-red-500"}`}>
                                    {change.balance > 0 ? "+" : null}{change.balance}
                                </span>
                            </li>
                        ))
                    ) : (
                        <li className="text-gray-500">No balance changes</li>
                    )}
                </ul>
            </div>
        </div>
    );
}
