// src/components/ConfirmationModal.jsx
import React from 'react';
import {useTranslation} from "react-i18next";

export default function ConfirmationModal({ isOpen, onConfirm, onCancel }) {
    const { t } = useTranslation();

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 shadow-lg rounded-lg max-w-md w-full">
                <h2 className="text-lg font-semibold mb-4">{t(`onboarding.confirmation_close.confirm_close`)}</h2>
                <p className="mb-6">{t(`onboarding.confirmation_close.text`)}</p>
                <div className="flex justify-end space-x-4">
                    <button
                        className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-300"
                        onClick={onCancel}
                    >
                        {t(`onboarding.confirmation_close.cancel`)}
                    </button>
                    <button
                        className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                        onClick={onConfirm}
                    >
                        {t(`onboarding.confirmation_close.confirm`)}
                    </button>
                </div>
            </div>
        </div>
    );
}
