import React, { useState } from "react";
import { XMarkIcon, ArrowLeftIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import mediaURL from "../utils/mediaUrl";
import AdvertisementTemplateForm from "./AdvertisementTemplateForm";
import {useTranslation} from "react-i18next";

const steps = {
    MAIN: "main",
    NEW: "new",
    SINGLE: "single"
};

const isImageFile = (file) => /\.(png|jpeg|jpg|gif)$/i.test(file);
const isVideoFile = (file) => /\.(webm|mp4)$/i.test(file);

export default function TemplatesModal({ isOpen, onClose, channel }) {
    const { t } = useTranslation();
    const [step, setStep] = useState(steps.MAIN);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    if (!isOpen) return null;

    const switchToMain = () => {
        setStep(steps.MAIN);
        setSelectedTemplate(null);
    };

    const switchToNew = () => {
        setStep(steps.NEW);
    };

    const switchToSingle = (template) => {
        setSelectedTemplate(template);
        setStep(steps.SINGLE);
    };

    // Function to style status
    const getStatusStyle = (status) => {
        switch (status) {
            case 'draft': return 'bg-yellow-100 text-yellow-800';
            case 'published': return 'bg-green-100 text-green-800';
            case 'rejected': return 'bg-red-100 text-red-800';
            case 'paused': return 'bg-gray-100 text-gray-800';
            default: return 'bg-gray-100 text-gray-800';
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 mb-16">
            <div className="bg-white rounded-lg max-w-lg w-full p-6 overflow-hidden relative h-full overflow-y-auto">
                {/* Main List of Templates */}
                {step === steps.MAIN &&
                    <>
                        <div className="flex flex-row justify-between items-center mt-2 px-2 w-full">
                            <XMarkIcon className="w-6 h-6 text-gray-500 cursor-pointer" onClick={onClose} />
                            <PlusCircleIcon className="w-6 h-6 text-gray-500 cursor-pointer" onClick={switchToNew} />
                        </div>
                        <div className="mt-4 space-y-4">
                            {channel?.advertisement_templates.length === 0 && <h1>{t('advertisement_template.no_advertisement_templates')}</h1>}
                            {channel?.advertisement_templates.map((template) => (
                                <div
                                    key={template.uuid}
                                    onClick={() => switchToSingle(template)}
                                    className="p-4 bg-gray-50 rounded-lg shadow-md cursor-pointer hover:bg-gray-100 transition"
                                >
                                    <div className="flex justify-between items-center">
                                        <h3 className="font-semibold text-lg">{template.title}</h3>
                                        <span className={`text-sm font-semibold px-2 py-1 rounded-full ${getStatusStyle(template.status)}`}>
                                            {t(`template_status.${template.status}`)}
                                        </span>
                                    </div>
                                    <p className="text-sm text-gray-500 mt-1">{template.text.slice(0, 50)}{template.text.length > 50 ? '...' : null}</p>
                                </div>
                            ))}
                        </div>
                    </>
                }

                {/* New Template Step */}
                {step === steps.NEW &&
                    <>
                        <div className="flex flex-row justify-between items-center mt-2 px-2">
                            <ArrowLeftIcon className="w-6 h-6 text-gray-500 cursor-pointer" onClick={switchToMain} />
                            <h2 className="text-lg font-semibold">{t('advertisement_template.create_new_template')}</h2>
                        </div>
                        <AdvertisementTemplateForm channelId={channel.uuid} onSuccess={switchToMain} />
                    </>
                }

                {/* Single Template View */}
                {step === steps.SINGLE && selectedTemplate &&
                    <>
                        <div className="flex flex-row justify-between items-center mt-2 px-2">
                            <ArrowLeftIcon className="w-6 h-6 text-gray-500 cursor-pointer" onClick={switchToMain} />
                            <h2 className="text-lg font-semibold">{selectedTemplate.title}</h2>
                        </div>
                        <div className="mt-4">
                            <div className="mt-4 flex justify-between items-center">
                                <span className={`text-sm font-semibold px-2 py-1 rounded-full ${getStatusStyle(selectedTemplate.status)}`}>
                                    {t(`template_status.${selectedTemplate.status}`)}
                                </span>
                                <span className="text-gray-600 text-sm hidden">Priority: {selectedTemplate.priority}</span>
                            </div>
                            <p className="text-sm text-gray-500 mb-2">{selectedTemplate.text}</p>
                            <div className="grid gap-2 grid-cols-2 justify-center items-center">
                                {selectedTemplate.media_files.map((file, idx) => (
                                    <div key={idx} className="w-full rounded-md shadow-sm">
                                        {isImageFile(file) ? (
                                            <img
                                                src={mediaURL(file)}
                                                alt={`media ${idx + 1}`}
                                                className="w-full object-cover rounded-md"
                                            />
                                        ) : isVideoFile(file) ? (
                                            <video
                                                src={mediaURL(file)}
                                                className="w-full h-full object-cover rounded-md"
                                                controls
                                                preload="metadata"
                                            />
                                        ) : null}
                                    </div>
                                ))}
                            </div>

                        </div>
                    </>
                }
            </div>
        </div>
    );
}
