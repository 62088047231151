// src/features/channels/channelsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { refreshBalance } from '../auth/authSlice';
import axios from 'axios';
import {handleApiError} from "../../utils/errorHandler";

export const fetchChannels = createAsyncThunk(
    'channels/fetchChannels',
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const { accessToken, tokenType } = state.auth;
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/channels`,
                {
                    headers: {
                        Authorization: `${tokenType} ${accessToken}`,
                        'ngrok-skip-browser-warning': '69420',
                    },
                });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchRegisteredChannels = createAsyncThunk(
    'channels/fetchRegisteredChannels',
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const { accessToken, tokenType } = state.auth;
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/registered-channels`,
                {
                    headers: {
                        Authorization: `${tokenType} ${accessToken}`,
                        'ngrok-skip-browser-warning': '69420',
                    },
                });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchCategories = createAsyncThunk(
    'channels/fetchCategories',
    async (_, thunkAPI) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/channel/categories`,
            {
                headers: {
                    'ngrok-skip-browser-warning': '69420',
                },
            });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchCountries = createAsyncThunk(
    'channels/fetchCountries',
    async (_, thunkAPI) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/channel/countries`,
                {
                    headers: {
                        'ngrok-skip-browser-warning': '69420',
                    },
                });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const createChannel = createAsyncThunk(
    'channels/createChannel',
    async (data, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const { accessToken, tokenType } = state.auth;
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/channel`,
                data,
                {
                    headers: {
                        Authorization: `${tokenType} ${accessToken}`,
                        'ngrok-skip-browser-warning': '69420',
                    },
                });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const createAdvertisementTemplate = createAsyncThunk(
    'channels/createAdvertisementTemplate',
    async (data, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const { accessToken, tokenType } = state.auth;
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/channel/advertisement_template`,
                data,
                {
                    headers: {
                        Authorization: `${tokenType} ${accessToken}`,
                        'ngrok-skip-browser-warning': '69420',
                    },
                });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const refreshChannel = createAsyncThunk(
    'channels/refreshChannel',
    async (uuid, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const { accessToken, tokenType } = state.auth;
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/channel/refresh/${uuid}`,
                {},
                {
                    headers: {
                        Authorization: `${tokenType} ${accessToken}`,
                        'ngrok-skip-browser-warning': '69420',
                    },
                });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchSuggestions = createAsyncThunk(
    'channels/fetchSuggestions',
    async (data, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const { accessToken, tokenType } = state.auth;
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/application/suggestions`,
                data,
                {
                    headers: {
                        Authorization: `${tokenType} ${accessToken}`,
                        'ngrok-skip-browser-warning': '69420',
                    },
                });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const createApplication = createAsyncThunk(
    "channels/createApplication",
    async (data, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const { accessToken, tokenType } = state.auth;
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/application/`,
                data,
                {
                    headers: {
                        Authorization: `${tokenType} ${accessToken}`,
                        'ngrok-skip-browser-warning': '69420',
                    },
                });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const createApplicationWithBalanceRefresh = createAsyncThunk(
    "channels/createApplicationWithBalanceRefresh",
    async (data, thunkAPI) => {
        // First, dispatch createApplication
        const result = await thunkAPI.dispatch(createApplication(data));

        // If createApplication fulfilled, then dispatch refreshBalance
        if (createApplication.fulfilled.match(result)) {
            await thunkAPI.dispatch(refreshBalance());
            return result.payload;
        }

        return thunkAPI.rejectWithValue(result.payload || result.error)
    }
);

export const cancelApplication = createAsyncThunk(
    "channels/cancelApplication",
    async (applicationID, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const { accessToken, tokenType } = state.auth;
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/application/${applicationID}/cancel`,
                {},
                {
                    headers: {
                        Authorization: `${tokenType} ${accessToken}`,
                        'ngrok-skip-browser-warning': '69420',
                    },
                });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const cancelApplicationWithBalanceRefresh = createAsyncThunk(
    "channels/cancelApplicationWithBalanceRefresh",
    async (applicationID, thunkAPI) => {
        // First, dispatch cancelApplication
        const result = await thunkAPI.dispatch(cancelApplication(applicationID));

        // If createApplication fulfilled, then dispatch refreshBalance
        if (cancelApplication.fulfilled.match(result)) {
            await thunkAPI.dispatch(refreshBalance());
            return result.payload;
        }

        return thunkAPI.rejectWithValue(result.payload || result.error); // Return the original result if needed
    }
);

export const approveApplication = createAsyncThunk(
    "channels/approveApplication",
    async (applicationID, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const { accessToken, tokenType } = state.auth;
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/application/${applicationID}/approve`,
                {},
                {
                    headers: {
                        Authorization: `${tokenType} ${accessToken}`,
                        'ngrok-skip-browser-warning': '69420',
                    },
                });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const rejectApplication = createAsyncThunk(
    "channels/rejectApplication",
    async (applicationID, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const { accessToken, tokenType } = state.auth;
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/application/${applicationID}/reject`,
                {},
                {
                    headers: {
                        Authorization: `${tokenType} ${accessToken}`,
                        'ngrok-skip-browser-warning': '69420',
                    },
                });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);


const channelsSlice = createSlice({
    name: 'channels',
    initialState: {
        channels: [],
        registeredChannels: [],
        categories: [],
        countries: [],
        suggestions: [],
        status: 'idle',
        error: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchChannels.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchChannels.fulfilled, (state, action) => {
                state.channels = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchChannels.rejected, (state, action) => {
                state.status = 'failed';
                handleApiError(action.payload);
                state.error = action.payload || 'Fetching channels failed';
            })
            .addCase(fetchRegisteredChannels.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchRegisteredChannels.fulfilled, (state, action) => {
                state.registeredChannels = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchRegisteredChannels.rejected, (state, action) => {
                state.status = 'failed';
                handleApiError(action.payload);
                state.error = action.payload || 'Fetching registered channels failed';
            })
            .addCase(fetchCategories.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.categories = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                state.status = 'failed';
                handleApiError(action.payload);
                state.error = action.payload || 'Fetching categories failed';
            })
            .addCase(fetchCountries.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCountries.fulfilled, (state, action) => {
                state.countries = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchCountries.rejected, (state, action) => {
                state.status = 'failed';
                handleApiError(action.payload);
                state.error = action.payload || 'Fetching countries failed';
            })
            .addCase(createChannel.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createChannel.fulfilled, (state, action) => {
                state.channels = [...state.channels, action.payload]
                state.status = 'succeeded';
            })
            .addCase(createChannel.rejected, (state, action) => {
                state.status = 'failed';
                handleApiError(action.payload);
                state.error = action.payload || 'Creating channel failed';
            })
            .addCase(refreshChannel.pending, (state) => {
                state.channels = [];
                state.status = 'loading';
            })
            .addCase(refreshChannel.fulfilled, (state, action) => {
                state.channels = action.payload
                state.status = 'succeeded';
            })
            .addCase(refreshChannel.rejected, (state, action) => {
                state.status = 'failed';
                handleApiError(action.payload);
                state.error = action.payload || 'Refreshing channel failed';
            })
            .addCase(fetchSuggestions.pending, (state) => {
                state.suggestions = [];
                state.status = 'loading';
            })
            .addCase(fetchSuggestions.fulfilled, (state, action) => {
                state.suggestions = action.payload
                state.status = 'succeeded';
            })
            .addCase(fetchSuggestions.rejected, (state, action) => {
                state.status = 'failed';
                handleApiError(action.payload);
                state.error = action.payload || 'Fetching suggestions failed';
            })
            .addCase(createAdvertisementTemplate.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createAdvertisementTemplate.fulfilled, (state, action) => {
                const newTemplate = action.payload;
                const { channel_id } = newTemplate;
                const channel = state.channels.find((channel) => channel.uuid === channel_id);
                if (channel) channel.advertisement_templates.push(newTemplate);
                state.status = 'succeeded';
            })
            .addCase(createAdvertisementTemplate.rejected, (state, action) => {
                state.status = 'failed';
                handleApiError(action.payload);
                state.error = action.payload || 'Creating advertisement template failed';
            })
            .addCase(createApplicationWithBalanceRefresh.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createApplicationWithBalanceRefresh.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newApplication = action.payload;

                const senderChannelId = newApplication?.channel_sender?.uuid;
                const channel = state.channels.find((channel) => channel.uuid === senderChannelId);

                if (channel) channel.sent_applications.push(newApplication);
                state.suggestions = state.suggestions.filter(suggestion => suggestion.uuid !== newApplication?.channel_receiver?.uuid);
            })
            .addCase(createApplicationWithBalanceRefresh.rejected, (state, action) => {
                state.status = 'failed';
                handleApiError(action.payload);
                state.error = action.payload || 'Creating application failed';
            })
            .addCase(cancelApplicationWithBalanceRefresh.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(cancelApplicationWithBalanceRefresh.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const application_id = action.payload.application_id;
                const channel_id = action.payload.channel_id;
                const channel = state.channels.find((channel) => channel.uuid === channel_id);
                if (channel) channel.sent_applications = channel.sent_applications.filter(application => application.uuid !== application_id)
            })
            .addCase(cancelApplicationWithBalanceRefresh.rejected, (state, action) => {
                state.status = 'failed';
                handleApiError(action.payload);
                state.error = action.payload || 'Cancelling application failed';
            })
            .addCase(approveApplication.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(approveApplication.fulfilled, (state, action) => {
                const application_id = action.payload.application_id;
                const channel_id = action.payload.channel_id;
                state.channels = state.channels.map(channel => {
                    if (channel.uuid !== channel_id) return channel
                    return {
                        ...channel,
                        applications: channel.applications.map(app => {
                            if (app.uuid !== application_id) return app
                            return {...app, status: 'Approved'}
                        })
                    }
                })
                state.status = 'succeeded';
            })
            .addCase(approveApplication.rejected, (state, action) => {
                state.status = 'failed';
                handleApiError(action.payload);
                state.error = action.payload || 'Approving application failed';
            })
            .addCase(rejectApplication.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(rejectApplication.fulfilled, (state, action) => {
                const application_id = action.payload.application_id;
                const channel_id = action.payload.channel_id;
                state.channels = state.channels.map(channel => {
                    if (channel.uuid !== channel_id) return channel
                    return {
                        ...channel,
                        applications: channel.applications.map(app => {
                            if (app.uuid !== application_id) return app
                            return {...app, status: 'Rejected'}
                        })
                    }
                })
                state.status = 'succeeded';
            })
            .addCase(rejectApplication.rejected, (state, action) => {
                state.status = 'failed';
                handleApiError(action.payload);
                state.error = action.payload || 'Rejecting application failed';
            });
    },
});

export default channelsSlice.reducer;
