import React, { useEffect } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";


export default function Layout(){
    const location = useLocation();

    useEffect(() => {
        const mainElement = document.getElementById('main-content');
        if (mainElement) {
            mainElement.scrollTo(0, 0);
        }
    }, [location]);

    return (
        <div className="h-screen flex flex-col max-w-xl mx-auto">
            <main id="main-content" className="min-h-[calc(100%-64px)] bg-white flex-grow p-4 sticky h-full overflow-y-auto">
                <Outlet />
            </main>
            <Footer />
        </div>
    );
};
