import React from "react";
import {useTranslation} from "react-i18next";

export default function FirstOnboarding() {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col items-center mt-6">
            <img
                src="/images/onboarding/onboarding5.png"
                alt="Onboarding 5"
                className="w-full object-contain"
            />
            <h2 className="text-3xl text-center mt-4">{t(`onboarding.fifth`)}</h2>
        </div>
    )
}
