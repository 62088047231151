export default function numberFormatter(num, digits = 2) {
    if (isNaN(num) || num === null || num === undefined) {
        return "0";
    }

    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "K" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];

    const regexp = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup.findLast(item => num >= item.value);

    if (!item) return "0";

    const formattedNumber = (num / item.value).toFixed(digits).replace(regexp, '');
    return formattedNumber.concat(item.symbol);
}
