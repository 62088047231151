import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {createAdvertisementTemplate} from "../features/channels/channelsSlice";
import {useTranslation} from "react-i18next";

export default function AdvertisementTemplateForm({ channelId, onSuccess }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const [files, setFiles] = useState([]);
    const [error, setError] = useState(null);

    const validateForm = () => {
        if (!title || title.length < 3 || title.length > 100) return t('error.INVALID_TITLE_LENGTH');
        if (!text || text.length < 10 || text.length > 500) return t('error.INVALID_TEXT_LENGTH');
        return null;
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);

        // Check file size and limit
        if (selectedFiles.length + files.length > 10) {
            setError(t('advertisement_template.limit_error'));
            return;
        }
        if (selectedFiles.some(file => file.size > 10 * 1024 * 1024)) {
            setError(t('advertisement_template.size_error'));
            return;
        }

        setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
        setError(null);
    };

    const handleFileDelete = (index) => {
        setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const validationError = validateForm();
        if (validationError) {
            setError(validationError);
            return;
        }

        if (!title || !text || !channelId) {
            setError(t('advertisement_template.fill_error'));
            return;
        }

        const formData = new FormData();
        formData.append("title", title);
        formData.append("text", text);
        formData.append("channel_id", channelId);
        files.forEach((file) => formData.append("files", file));
        dispatch(createAdvertisementTemplate(formData)).then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {
                onSuccess();
                setFiles([]);
                setTitle("");
                setText("");
                setError(null);
            }
        });
    };

    return (
        <form onSubmit={handleSubmit} className="flex flex-col space-y-4 p-4">
            <h2 className="text-lg font-semibold">{t('advertisement_template.create_advertisement_template')}</h2>
            {error && <p className="text-red-500">{error}</p>}

            {/* Title */}
            <input
                type="text"
                placeholder={t('advertisement_template.title')}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="border p-2 rounded"
                required
            />

            {/* Text */}
            <textarea
                placeholder={t('advertisement_template.text')}
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="border p-2 rounded"
                rows={4}
                required
            />

            {/* File Upload */}
            <input
                type="file"
                accept=".png, .jpeg, .jpg, .webm, .gif, .mp4"
                multiple
                onChange={handleFileChange}
                className="border p-2 rounded"
            />

            {/* File List with Delete Option */}
            <ul className="space-y-1">
                {files.map((file, index) => (
                    <li key={index} className="text-sm flex justify-between items-center">
                        <span>{file.name} - {(file.size / 1024 / 1024).toFixed(2)} MB</span>
                        <button
                            type="button"
                            className="text-red-500 hover:text-red-700 ml-2"
                            onClick={() => handleFileDelete(index)}
                        >
                            {t('advertisement_template.delete')}
                        </button>
                    </li>
                ))}
            </ul>

            <button
                type="submit"
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
            >
                {t('advertisement_template.create')}
            </button>
        </form>
    );
}
