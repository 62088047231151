// src/features/auth/authSlice.js
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import {handleApiError} from "../../utils/errorHandler";

export const authorizeUser = createAsyncThunk(
    'auth/authorizeUser',
    async (data, thunkAPI) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth`, data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const finishOnboarding = createAsyncThunk(
    'user/finishOnboarding',
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const { accessToken, tokenType } = state.auth;

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/user/finish-onboarding`,
                {},
                {
                    headers: {
                        Authorization: `${tokenType} ${accessToken}`,
                    },
                });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const closeOnboarding = createAsyncThunk(
    'user/closeOnboarding',
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const { accessToken, tokenType } = state.auth;

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/user/close-onboarding`,
                {},
                {
                    headers: {
                        Authorization: `${tokenType} ${accessToken}`,
                    },
                });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getReferrals = createAsyncThunk(
    'user/getReferrals',
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const { accessToken, tokenType } = state.auth;

            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/user/referrals`,
                {
                    headers: {
                        Authorization: `${tokenType} ${accessToken}`,
                        'ngrok-skip-browser-warning': '69420',
                    },
                });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const refreshBalance = createAsyncThunk(
    'user/refreshBalance',
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const { accessToken, tokenType } = state.auth;

            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/user/refresh-balance`,
                {
                    headers: {
                        Authorization: `${tokenType} ${accessToken}`,
                        'ngrok-skip-browser-warning': '69420',
                    },
                });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const updateLanguage = createAsyncThunk(
    'auth/updateLanguage',
    async (languageCode, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const { accessToken, tokenType } = state.auth;

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/user/change-language`,
                { language_code: languageCode },
                {
                    headers: {
                        Authorization: `${tokenType} ${accessToken}`,
                    },
                }
            );
            return { languageCode: response.data.language_code || languageCode };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response?.data || 'Failed to update language');
        }
    }
);

export const collectLogData = createAsyncThunk(
    'auth/collectLogData',
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const user = state.auth.user;
            const { accessToken, tokenType } = state.auth;

            if (!user) throw new Error("User not authenticated");

            // Gather user information
            const userAgent = navigator.userAgent;
            const screenResolution = `${window.screen.width}x${window.screen.height}`;
            const language = navigator.language;
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            // Get IP address
            let ipAddress = null;
            try {
                const ipResponse = await fetch('https://api.ipify.org?format=json');
                const ipData = await ipResponse.json();
                ipAddress = ipData.ip || null;
            } catch (ipError) {
                console.error('Error fetching IP address:', ipError);
            }

            // Get battery status
            let batteryStatus = null;
            if (navigator.getBattery) {
                try {
                    const battery = await navigator.getBattery();
                    batteryStatus = `${Math.round(battery.level * 100)}%`;
                } catch (batteryError) {
                    console.error('Error fetching battery status:', batteryError);
                }
            }

            // Get performance data
            const performanceData = window.performance.timing.toJSON();

            // Prepare log data
            const logData = {
                telegram_id: user.telegram_id,
                first_name: user.first_name,
                last_name: user.last_name || '',
                username: user.username || '',
                user_agent: userAgent,
                screen_resolution: screenResolution,
                language: language,
                timezone: timezone,
                ip_address: ipAddress,
                battery_status: batteryStatus,
                performance: performanceData,
            };

            await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/user/user-activity`,
                { ...logData },
                {
                    headers: {
                        Authorization: `${tokenType} ${accessToken}`,
                    },
                }
            );
        } catch (error) {
            console.error('Error collecting log data:', error);
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);



const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        referralInfo: null,
        balanceChanges: [],
        isAuthenticated: false,
        language_code: "en",
        accessToken: null,
        tokenType: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(authorizeUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(authorizeUser.fulfilled, (state, action) => {
                state.user = action.payload.user;
                localStorage.setItem("languageCode", action.payload?.user?.language_code);
                state.balanceChanges = action.payload.balance_changes;
                state.isAuthenticated = true;
                state.accessToken = action.payload.access_token;
                state.tokenType = action.payload.token_type;
                state.status = 'succeeded';
            })
            .addCase(authorizeUser.rejected, (state, action) => {
                state.status = 'failed';
                handleApiError(action.payload);
                state.error = action.payload || 'Authorization failed';
            })
            .addCase(finishOnboarding.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(finishOnboarding.fulfilled, (state, action) => {
                state.user = action.payload.user;
                state.balanceChanges = action.payload.balance_changes;
                state.status = 'succeeded';
            })
            .addCase(finishOnboarding.rejected, (state, action) => {
                state.status = 'failed';
                handleApiError(action.payload);
                state.error = action.payload || 'Finish onboarding failed';
            })
            .addCase(closeOnboarding.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(closeOnboarding.fulfilled, (state, action) => {
                state.user = action.payload.user;
                state.balanceChanges = action.payload.balance_changes;
                state.status = 'succeeded';
            })
            .addCase(closeOnboarding.rejected, (state, action) => {
                state.status = 'failed';
                handleApiError(action.payload);
                state.error = action.payload || 'Close onboarding failed';
            })
            .addCase(getReferrals.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getReferrals.fulfilled, (state, action) => {
                state.referralInfo = action.payload;
                state.status = 'succeeded';
            })
            .addCase(getReferrals.rejected, (state, action) => {
                state.status = 'failed';
                handleApiError(action.payload);
                state.error = action.payload || 'Fetch referral info failed';
            })
            .addCase(refreshBalance.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(refreshBalance.fulfilled, (state, action) => {
                state.balanceChanges = action.payload.balance_changes;
                state.user = action.payload.user;
                state.status = 'succeeded';
            })
            .addCase(refreshBalance.rejected, (state, action) => {
                state.status = 'failed';
                handleApiError(action.payload);
                state.error = action.payload || 'Fetch referral info failed';
            })
            .addCase(updateLanguage.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateLanguage.fulfilled, (state, action) => {
                state.language_code = action.payload.languageCode;
                localStorage.setItem('languageCode', action.payload.languageCode);
                if (state.user) state.user.language_code = action.payload.languageCode;
                state.status = 'succeeded';
            })
            .addCase(updateLanguage.rejected, (state, action) => {
                state.status = 'failed';
                handleApiError(action.payload);
                state.error = action.payload || 'Failed to update language';
            })
            .addCase(collectLogData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(collectLogData.fulfilled, (state, action) => {
                // state.logData = action.payload;
                state.status = 'succeeded';
            })
            .addCase(collectLogData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'Failed to collect log data';
            });
    },
});

export const selectLanguageCode = createSelector(
    [(state) => state.auth.user],
    (user) => {
        if (!user || !user.language_code) return "en";
        if (user.language_code === "ru") return "ru";
        return "en"
    }
);


export default authSlice.reducer;
