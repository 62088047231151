import { toast } from "react-toastify";
import i18n from "i18next";

export const handleApiError = (error) => {
    if (!error) {
        toast.error(i18n.t("error.UNKNOWN_ERROR"));
        return;
    }

    // Handle when detail is a string (e.g., "INVALID_LANGUAGE")
    if (typeof error.detail === "string") {
        const translatedMessage = i18n.t(`error.${error.detail}`, error.detail);
        toast.error(translatedMessage);
        return;
    }

    // Handle Pydantic validation errors (array of details)
    if (Array.isArray(error.detail)) {
        error.detail.forEach((detail) => {
            toast.error(detail.msg || i18n.t("error.VALIDATION_ERROR", "Validation error"));
        });
        return;
    }

    // Handle unexpected error formats
    toast.error(error.message || i18n.t("error.UNKNOWN_ERROR", "An unknown error occurred"));
};
