import React, { useEffect, useState } from 'react';
import {MagnifyingGlassIcon, FunnelIcon, EllipsisHorizontalIcon} from '@heroicons/react/24/outline';
import Balance from 'components/Balance';
import { useSelector, useDispatch } from 'react-redux';
import {
    createApplicationWithBalanceRefresh,
    fetchCategories,
    fetchCountries,
    fetchSuggestions,
    cancelApplicationWithBalanceRefresh,
} from '../features/channels/channelsSlice';
import numberFormatter from '../utils/numberFormatter';
import { MapPinIcon, UsersIcon } from "@heroicons/react/24/solid";
import mediaURL from "../utils/mediaUrl";
import BalanceLogo from "../components/BalanceLogo";
import FilterModal from "../components/FilterModal";
import {useTranslation} from "react-i18next";
import {getLocalizedName} from "../utils/nameFormatter";

const points = {
    ALL: "all",
    AVAILABLE: "available"
};

export default function SentAppsPage() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const channels = useSelector((state) => state.channels.channels);
    const suggestions = useSelector((state) => state.channels.suggestions);

    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [openMenuId, setOpenMenuId] = useState(null);

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const [selectedPoints, setSelectedPoints] = useState(points.ALL);
    const [selectedCategories, setSelectedCategories] = useState(null);
    const [selectedCountries, setSelectedCountries] = useState(null);

    const statuses = ['All', 'Pending', 'Approved'];

    useEffect(() => {
        dispatch(fetchCategories());
        dispatch(fetchCountries());
    }, [dispatch]);

    const handleChannelChange = (e) => {
        const channel = channels.find((ch) => ch.uuid === e.target.value);
        setSelectedChannel(channel);
        setSelectedTemplate(null);
    };

    const onApplyFilter = () => {
        const data = {
            points: selectedPoints,
            categories: selectedCategories,
            countries: selectedCountries,
        };
        dispatch(fetchSuggestions(data));
        setIsFilterModalOpen(false);
    };

    const handleTemplateChange = (e) => {
        const template = selectedChannel?.advertisement_templates.find((tpl) => tpl.uuid === e.target.value);
        setSelectedTemplate(template);
        setSelectedPoints(points.ALL);
        setSelectedCategories([]);
        setSelectedCountries([]);

        const data = {
            points: points.ALL,
            categories: [],
            countries: [],
            template_id: template.uuid,
        };
        dispatch(fetchSuggestions(data));
    };

    const handleCreate = async (channel_id) => {
        const data = {
            receiver_channel_id: channel_id,
            template_id: selectedTemplate.uuid,
            sender_channel_id: selectedChannel.uuid
        };
        const response = await dispatch(createApplicationWithBalanceRefresh(data));
        if (response.meta.requestStatus === 'fulfilled') {
            const newApplication = response.payload;

            // Update the selected channel with the new sent application
            setSelectedChannel(prevChannel => ({
                ...prevChannel,
                sent_applications: [...prevChannel.sent_applications, newApplication]
            }));
        }
    };

    const filteredSentApplications = selectedChannel?.sent_applications
        .filter((app) => app.status.toLowerCase() === 'pending' || app.status.toLowerCase() === 'approved')
        .filter((app) => selectedStatus === 'All' || app.status === selectedStatus)
        .filter((app) => app.advertisement_template.uuid === selectedTemplate?.uuid)
        .filter((app) => app.channel_receiver.name.toLowerCase().includes(searchTerm.toLowerCase())) || [];

    const toggleMenu = (uuid) => {
        setOpenMenuId((prev) => (prev === uuid ? null : uuid));
    };

    const handleCancel = (uuid) => {
        dispatch(cancelApplicationWithBalanceRefresh(uuid)).then((response) => {
            if (response.meta.requestStatus === 'fulfilled') {
                const application_id = response.payload.application_id;

                setSelectedChannel(prevChannel => ({
                    ...prevChannel,
                    sent_applications: prevChannel.sent_applications.filter(application => application.uuid !== application_id)
                }));
            }
        })

        setOpenMenuId(null);
    };

    return (
        <div className="flex-grow flex flex-col gap-2">
            {/* Header */}
            <div className="flex justify-between items-center border-b border-gray-200 px-4 py-2">
                <div className="flex items-center border-2 rounded-lg max-w-48 py-1 px-2">
                    <input
                        type="text"
                        className="border-none outline-none bg-transparent w-full"
                        placeholder={t('sent_apps_page.search_applications')}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <MagnifyingGlassIcon className="w-6 h-6 text-gray-500 mr-2" />
                </div>
                <Balance />
            </div>

            {/* Channel and Template Selectors */}
            <div className="px-4 py-2">
                <select
                    value={selectedChannel?.uuid || ''}
                    onChange={handleChannelChange}
                    className="w-full border-2 rounded-lg p-2 mb-2"
                >
                    <option value="" disabled>{t('sent_apps_page.select_channel')}</option>
                    {channels.map((channel) => (
                        <option key={channel.uuid} value={channel.uuid}>{channel.name}</option>
                    ))}
                </select>
                {selectedChannel && (
                    <select
                        value={selectedTemplate?.uuid || ''}
                        onChange={handleTemplateChange}
                        className="w-full border-2 rounded-lg p-2"
                    >
                        <option value="" disabled>{t('sent_apps_page.select_advertisement_template')}</option>
                        {selectedChannel.advertisement_templates
                            .filter((tpl) => tpl.status === 'published')
                            .map((template) => (
                                <option key={template.uuid} value={template.uuid}>{template.title}</option>
                            ))}
                    </select>
                )}
            </div>

            {selectedChannel && selectedTemplate && (
                <>
                    {/* Status Filter Tabs */}
                    <div className="flex flex-row w-full justify-between items-center">
                        <div className="flex overflow-x-auto space-x-4 px-4 py-2 border-b border-gray-200 hide-scrollbar w-full">
                            {statuses.map((status) => (
                                <button
                                    key={status}
                                    className={`text-blue-500 whitespace-nowrap focus:outline-none ${selectedStatus === status && 'font-bold'}`}
                                    onClick={() => setSelectedStatus(status)}
                                >
                                    {t(`application_status.${status}`)}
                                </button>
                            ))}
                        </div>
                        <button onClick={() => setIsFilterModalOpen(true)} className="p-2 w-12 rounded-lg border-2">
                            <FunnelIcon className="w-6 h-6 text-gray-500" />
                        </button>
                    </div>

                    <FilterModal
                        isOpen={isFilterModalOpen}
                        onClose={() => setIsFilterModalOpen(false)}
                        selectedPoints={selectedPoints}
                        setSelectedPoints={setSelectedPoints}
                        selectedCategories={selectedCategories}
                        setSelectedCategories={setSelectedCategories}
                        selectedCountries={selectedCountries}
                        setSelectedCountries={setSelectedCountries}
                        onApply={onApplyFilter}
                    />

                    {/* Sent Applications Grid */}
                    <div className="grid grid-cols-2 gap-4 p-4">
                        {filteredSentApplications.length > 0 ? (
                            filteredSentApplications.map((application) => (
                                <div key={application.uuid} className="bg-gray-100 rounded-lg p-4 shadow-md">
                                    <div className="flex flex-col space-y-2">
                                        <div className="flex justify-between">
                                            {application.channel_receiver.image ?
                                                <img src={mediaURL(application.channel_receiver.image)} alt={application.channel_receiver.name} className="w-10 h-10 rounded-full" />
                                                :
                                                <img src="/logo.png" alt={application.channel_receiver.name} className="w-10 h-10 rounded-full" />
                                            }
                                            <div className="relative">
                                                <EllipsisHorizontalIcon
                                                    className="w-6 h-6 text-gray-500 cursor-pointer"
                                                    onClick={() => toggleMenu(application.uuid)}
                                                />

                                                {/* Popup Menu */}
                                                {openMenuId === application.uuid && (
                                                    <div className="absolute right-0 mt-2 w-32 bg-white shadow-lg rounded-md z-10">
                                                        <ul>
                                                            <li
                                                                onClick={() => handleCancel(application.uuid)}
                                                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                                            >
                                                                {t(`sent_apps_page.cancel`)}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <a
                                            href={application.channel_receiver.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="font-semibold text-lg hover:underline"
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            {application.channel_receiver.name}
                                        </a>
                                        <div className="text-sm text-gray-500 h-4 overflow-clip">{application.channel_receiver.description?.slice(0, 20)}{application.channel_receiver.description?.length > 20 ? '...' : null}</div>
                                        <div className="flex items-center">
                                            <span className="border-2 border-blue-600 text-blue-600 text-sm font-medium px-2 rounded-full mr-2">
                                                {getLocalizedName(application?.channel_receiver?.category)}
                                            </span>
                                        </div>
                                        <div className="flex space-x-2 text-sm text-gray-500">
                                            <MapPinIcon className="w-5 h-5" /><span>{application.channel_receiver.country.country_code}</span>
                                            <UsersIcon className="w-5 h-5" /><span>{numberFormatter(application.channel_receiver.followers)}</span>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <button className={`px-4 py-1 rounded-full w-full bg-blue-100 text-blue-500`}>
                                            {t(`application_status.${application.status}`)}
                                        </button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="text-center text-gray-500 col-span-2">{t(`sent_apps_page.no_sent_applications_found`)}</div>
                        )}
                    </div>

                    {/* Suggestions Grid */}
                    {selectedStatus === "All" &&
                        <div className="grid grid-cols-2 gap-4 p-4">
                            {suggestions.length > 0 ? (
                                suggestions.map((suggestion, index) => (
                                    <div key={index} className="bg-gray-100 rounded-lg p-4 shadow-md">
                                        <div className="flex flex-col space-y-2">
                                            <div className="flex justify-between">
                                                {suggestion?.image ?
                                                    <img src={mediaURL(suggestion?.image)} alt={suggestion?.name} className="w-10 h-10 rounded-full" />
                                                    :
                                                    <img src="/logo.png" alt={suggestion?.name} className="w-10 h-10 rounded-full" />
                                                }
                                            </div>
                                            <a
                                                href={suggestion.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="font-semibold text-lg hover:underline"
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                {suggestion.name}
                                            </a>
                                            <div className="text-sm text-gray-500 h-4 overflow-clip">{suggestion?.description?.slice(0, 20)}{suggestion?.description?.length > 20 ? '...' : null}</div>
                                            <div className="flex items-center">
                                                <span className="border-2 border-blue-600 text-blue-600 text-sm font-medium px-2 rounded-full mr-2">
                                                    {getLocalizedName(suggestion?.category)}
                                                </span>
                                            </div>
                                            <div className="flex space-x-2 text-sm text-gray-500">
                                                <MapPinIcon className="w-5 h-5" /><span>{suggestion.country.country_code}</span>
                                                <UsersIcon className="w-5 h-5" /><span>{numberFormatter(suggestion.followers)}</span>
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <button
                                                onClick={() => handleCreate(suggestion.uuid)}
                                                className="px-2 py-1 rounded-full w-full bg-blue-100 text-blue-500 flex flex-row justify-around items-center gap-1 text-center text-sm"
                                            >
                                                {t(`sent_apps_page.request`)} <BalanceLogo className="w-4 h-4" /> {suggestion.channel_price}
                                            </button>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="text-center text-gray-500 col-span-2">{t(`sent_apps_page.no_suggestions_found`)}</div>
                            )}
                        </div>
                    }

                </>
            )}
        </div>
    );
}
