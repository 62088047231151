// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth/authSlice';
import channelsSlice from "./features/channels/channelsSlice";

const store = configureStore({
    reducer: {
        auth: authReducer,
        channels: channelsSlice,
    },
});

export default store;
