import React, {useState} from "react";
import numberFormatter from "utils/numberFormatter"
import { useSelector } from "react-redux";
import BalanceChangesModal from "./BalanceChangesModal";
import BalanceLogo from "./BalanceLogo";


export default function Balance() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const balance = useSelector((state) => state.auth.user?.balance);
    const balanceChanges = useSelector((state) => state.auth?.balanceChanges);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div
                className="w-28 bg-gradient-primary h-10 rounded-2xl flex flex-row justify-between items-center text-primaryWhite text-xl px-4 font-bold cursor-pointer"
                onClick={handleOpenModal}
            >
                <BalanceLogo />
                <span> {numberFormatter(balance)} </span>
            </div>

            {/* Modal for balance changes */}
            <BalanceChangesModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                balanceChanges={balanceChanges}
                balance={balance}
            />
        </>
    );
}
