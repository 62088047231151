import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getReferrals } from "../features/auth/authSlice";
import { toast } from "react-toastify";
import {useTranslation} from "react-i18next";

export default function RefPage() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { referralInfo, status } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(getReferrals());
    }, [dispatch]);

    const handleInviteLinkClick = async (e) => {
        e.preventDefault();
        if (referralInfo?.invite_link) {
            try {
                await navigator.clipboard.writeText(referralInfo.invite_link);
                toast.success(t('ref_page.copied_link'));
            } catch {
                toast.error(t('ref_page.failed_copy_link'));
            }
        }
    };

    const handleInviteClick = (e) => {
        e.preventDefault();
        const inviteLink = `https://t.me/share/url?url=${encodeURIComponent(referralInfo?.invite_link)}`;
        window.open(inviteLink, '_blank');
    };


    return (
        <div className="flex flex-col items-center justify-center h-full space-y-6 p-6 bg-gray-100">
            <h1 className="text-4xl font-extrabold text-gray-800 text-center">{t('ref_page.title')}</h1>

            {/* Description */}
            <p className="text-center text-gray-600 max-w-md">
                {t('ref_page.text1')}
                <span className="font-semibold text-blue-600"> {t('ref_page.text2')}</span> {t('ref_page.text3')}
            </p>

            {status === 'succeeded' && referralInfo && (
                <div className="w-full max-w-lg bg-white shadow-lg rounded-lg p-6 space-y-4">
                    {/* Stats Section */}
                    <div className="flex flex-col items-center space-y-2">
                        <div className="w-full flex justify-between p-4 border-b">
                            <h2 className="text-lg font-semibold text-gray-600">{t('ref_page.total')}:</h2>
                            <span className="text-xl font-bold text-gray-800">{referralInfo.referrals}</span>
                        </div>
                        <div className="w-full flex justify-between p-4">
                            <h2 className="text-lg font-semibold text-gray-600">{t('ref_page.successful')}:</h2>
                            <span className="text-xl font-bold text-gray-800">{referralInfo.created_channels}</span>
                        </div>
                    </div>

                    {/* Invite Link */}
                    <div className="w-full text-center">
                        <h3 className="text-sm text-gray-500 mb-2">{t('ref_page.invite_link')}</h3>
                        <p
                            onClick={handleInviteLinkClick}
                            className="text-blue-600 font-semibold cursor-pointer hover:underline"
                            title="Click to copy"
                        >
                            {referralInfo.invite_link}
                        </p>
                    </div>

                    {/* Invite Button */}
                    <div className="w-full flex justify-center mt-4">
                        <a
                            href={`https://t.me/share/url?url=${encodeURIComponent(referralInfo.invite_link)}`}
                            onClick={handleInviteClick}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-blue-500 text-white px-6 py-2 rounded-full font-medium text-lg hover:bg-blue-600 transition duration-150 ease-in-out shadow-md"
                        >
                            {t('ref_page.invite_a_friend')}
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
}
