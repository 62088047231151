// components/FilterModal.jsx
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ArrowLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import {useTranslation} from "react-i18next";
import {getLocalizedName} from "../utils/nameFormatter";

export default function FilterModal({
    isOpen,
    onClose,
    selectedPoints,
    setSelectedPoints,
    selectedCategories,
    setSelectedCategories,
    selectedCountries,
    setSelectedCountries,
    onApply
}) {
    const { t } = useTranslation();
    const categories = useSelector((state) => state.channels.categories);
    const countries = useSelector((state) => state.channels.countries);

    const [searchInput, setSearchInput] = useState("");
    const [filteredCountries, setFilteredCountries] = useState([]);

    // Update filtered countries based on search input
    useEffect(() => {
        if (countries) {
            setFilteredCountries(
                countries.filter(country =>
                    getLocalizedName(country).toLowerCase().includes(searchInput.toLowerCase()) ||
                    country.country_code.toLowerCase().includes(searchInput.toLowerCase())
                )
            );
        }
    }, [searchInput, countries]);

    const handleCategorySelect = (categoryId) => {
        setSelectedCategories(prev => prev.includes(categoryId) ? prev.filter(id => id !== categoryId) : [...prev, categoryId]);
    };

    const handleCountrySelect = (countryId) => {
        setSelectedCountries(prev => prev.includes(countryId) ? prev.filter(id => id !== countryId) : [...prev, countryId]);
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 mb-16">
            <div className="bg-white rounded-lg max-w-lg w-full p-6 relative h-full overflow-y-auto">
                <div className="flex justify-between items-center mb-4">
                    <button onClick={onClose}>
                        <ArrowLeftIcon className="w-6 h-6 text-gray-500" />
                    </button>
                    <h2 className="text-lg font-semibold">{t('filter.filters')}</h2>
                    <button onClick={onClose}>
                        <XMarkIcon className="w-6 h-6 text-gray-500" />
                    </button>
                </div>

                {/* Display Selected Tags */}
                <div className="flex flex-wrap gap-2 mb-4">
                    {selectedPoints && (
                        <span className="px-3 py-1 rounded-full bg-blue-100 text-blue-500 font-medium">
                            {selectedPoints === 'all' ? t(`filter.All`) : t(`filter.Available`)}
                            <button onClick={() => setSelectedPoints('all')} className="ml-2 text-blue-500">×</button>
                        </span>
                    )}
                    {selectedCategories.map((categoryId) => {
                        const category = categories.find(cat => cat.id === categoryId);
                        return (
                            <span key={categoryId} className="px-3 py-1 rounded-full bg-blue-100 text-blue-500 font-medium">
                                {getLocalizedName(category)}
                                <button onClick={() => handleCategorySelect(categoryId)} className="ml-2 text-blue-500">×</button>
                            </span>
                        );
                    })}
                    {selectedCountries.map((countryId) => {
                        const country = countries.find(cntry => cntry.id === countryId);
                        return (
                            <span key={countryId} className="px-3 py-1 rounded-full bg-blue-100 text-blue-500 font-medium">
                                {getLocalizedName(country)}
                                <button onClick={() => handleCountrySelect(countryId)} className="ml-2 text-blue-500">×</button>
                            </span>
                        );
                    })}
                </div>

                {/* Points Filter */}
                <div className="mb-4">
                    <h3 className="text-sm font-semibold mb-2">Points</h3>
                    <div className="flex space-x-2">
                        {['All', 'Available'].map((point) => (
                            <button
                                key={point}
                                onClick={() => setSelectedPoints(point.toLowerCase())}
                                className={`px-4 py-1 rounded-full ${selectedPoints === point.toLowerCase() ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-600'}`}
                            >
                                {t(`filter.${point}`)}
                            </button>
                        ))}
                    </div>
                </div>

                {/* Category Filter */}
                <div className="mb-4">
                    <h3 className="text-sm font-semibold mb-2">{t('filter.category')}</h3>
                    <div className="flex flex-wrap gap-2">
                        {categories.map((category) => (
                            <button
                                key={category.id}
                                onClick={() => handleCategorySelect(category.id)}
                                className={`px-3 py-1 rounded-full ${selectedCategories.includes(category.id) ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-600'}`}
                            >
                                {getLocalizedName(category)}
                            </button>
                        ))}
                    </div>
                </div>

                {/* Country Filter with Search */}
                <div className="mb-4">
                    <h3 className="text-sm font-semibold mb-2">{t('filter.region')}</h3>
                    <div className="flex items-center border rounded-lg px-2 mb-2">
                        <MagnifyingGlassIcon className="w-5 h-5 text-gray-400 mr-2" />
                        <input
                            type="text"
                            placeholder={t('filter.search_region')}
                            className="w-full py-1 outline-none bg-transparent"
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                        />
                    </div>
                    <ul className="space-y-2 max-h-48 overflow-y-auto">
                        {filteredCountries.length > 0 ? (
                            filteredCountries.map((country) => (
                                <li
                                    key={country.id}
                                    onClick={() => handleCountrySelect(country.id)}
                                    className={`px-3 py-2 rounded-lg cursor-pointer flex justify-between items-center ${
                                        selectedCountries.includes(country.id) ? 'bg-blue-100 text-blue-600' : 'hover:bg-gray-100'
                                    }`}
                                >
                                    <span>{getLocalizedName(country)}</span>
                                    <span>{country.country_code}</span>
                                </li>
                            ))
                        ) : (
                            <li className="p-2 text-gray-500">{t('filter.no_regions_found')}</li>
                        )}
                    </ul>
                </div>

                {/* Apply Button */}
                <button
                    onClick={onApply}
                    className="w-full bg-gradient-to-r from-blue-500 to-blue-700 text-white py-2 rounded-lg"
                >
                    {t('filter.show')} ({selectedCategories.length + selectedCountries.length + (selectedPoints ? 1 : 0)})
                </button>
            </div>
        </div>
    );
}
