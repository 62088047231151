import React, { useState } from "react";
import FirstOnboarding from "./onboarding/FirstOnboarding";
import SecondOnboarding from "./onboarding/SecondOnboarding";
import ThirdOnboarding from "./onboarding/ThirdOnboarding";
import FourthOnboarding from "./onboarding/FourthOnboarding";
import FifthOnboarding from "./onboarding/FifthOnboarding";
import SixthOnboarding from "./onboarding/SixthOnboarding";
import { useDispatch } from 'react-redux';
import { finishOnboarding, closeOnboarding } from "../features/auth/authSlice";
import ConfirmationModal from "./onboarding/ConfirmationModal";
import {useTranslation} from "react-i18next";
const steps = 6;

export default function OnboardingModal({ isOpen }) {
    const [currentStep, setCurrentStep] = useState(0);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleNext = () => {
        if (currentStep < steps - 1) {
            setCurrentStep(currentStep + 1);
        } else {
            dispatch(finishOnboarding());
        }
    };

    const handleClose = () => {
        setIsConfirmationOpen(true);
    };

    const confirmClose = () => {
        dispatch(closeOnboarding());
        setIsConfirmationOpen(false);
    };

    const cancelClose = () => {
        setIsConfirmationOpen(false);
    };

    if (!isOpen) return null;

    const renderStepComponent = () => {
        switch (currentStep) {
            case 0:
                return <FirstOnboarding />;
            case 1:
                return <SecondOnboarding />;
            case 2:
                return <ThirdOnboarding />;
            case 3:
                return <FourthOnboarding />;
            case 4:
                return <FifthOnboarding />;
            case 5:
                return <SixthOnboarding />;
            default:
                setCurrentStep(0)
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white w-full p-6 shadow-lg relative max-w-lg h-full flex flex-col overflow-y-auto">
                <button className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 text-2xl" onClick={handleClose}>
                    ✕
                </button>

                <div className="text-center flex-grow mt-2">
                    {renderStepComponent()}
                </div>

                <div className="mt-4 flex justify-center space-x-2">
                    {Array.from({ length: steps }).map((_, index) => (
                        <div
                            key={index}
                            className={`w-6 h-2 rounded-full ${
                                index < currentStep
                                    ? "bg-darkBlue cursor-pointer" // Previous steps
                                    : index === currentStep
                                        ? "bg-gradient-primary cursor-pointer" // Current step
                                        : "bg-darkWhite" // Next steps
                            }`}
                            onClick={() => setCurrentStep(Math.min(index, currentStep))}
                        />
                    ))}
                </div>

                <div className="mt-6 flex justify-between items-center">
                    <button
                        className="w-full bg-gradient-primary text-white font-medium text-lg py-2 px-4 rounded-xl hover:bg-blue-700 active:scale-95 transition-transform duration-150 ease-in-out"
                        onClick={handleNext}
                    >
                        {currentStep < steps - 1 ? t(`onboarding.next`) : t(`onboarding.get_points`)}
                    </button>
                </div>
            </div>
            {/* Confirmation Modal */}
            {isConfirmationOpen && (
                <ConfirmationModal
                    isOpen={isConfirmationOpen}
                    onConfirm={confirmClose}
                    onCancel={cancelClose}
                />
            )}
        </div>
    );
}
