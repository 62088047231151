import { Route, Routes } from "react-router-dom";
import Layout from "components/Layout";
import ChannelsPage from "pages/ChannelsPage";
import SettingsPage from "pages/SettingsPage";
import AppsPage from "../pages/AppsPage";
import SentAppsPage from "../pages/SentAppsPage";
import RefPage from "../pages/RefPage";
import {APPS_ROUTE, CHANNELS_ROUTE, REF_ROUTE, SENT_APPS_ROUTE, SETTINGS_ROUTE} from "../constants/routerConstants";


export const AppRoutes = () => {
    return (
      <Routes>
          <Route element={<Layout />}>
              <Route path={`${CHANNELS_ROUTE}`} element={<ChannelsPage/>} />
              <Route path={`${APPS_ROUTE}`} element={<AppsPage/>} />
              <Route path={`${SENT_APPS_ROUTE}`} element={<SentAppsPage/>} />
              <Route path={`${REF_ROUTE}`} element={<RefPage/>} />
              <Route path={`${SETTINGS_ROUTE}`} element={<SettingsPage/>} />
          </Route>
      </Routes>
    );
};
